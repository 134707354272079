import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const LicenceContractIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 18 20">
        <path
            d="M12.274 0H0v20h16v-4.004h-1V19H1V1h9.999v4.025H15V8h1V3.955L12.274 0zm-.275 4.025V1.167l2.692 2.858h-2.692zm6 6.978l-1.991-1.992-6.06 6.069v1.953h1.941L18 11.003zm-7.052 4.491l5.061-5.068.572.572-5.102 5.035h-.531v-.539z"
            fillRule="evenodd"
        />
        <path d="M3.018 12.005h7.999v-1H3.018v1zm4.999 4H3.018v-1h4.999v1zm-4.999-8h8v-1h-8v1z" />
    </SvgIcon>
)
