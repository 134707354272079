import { SvgIcon, SvgIconProps } from '@mui/material'

export const YachtingIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 17 17">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
            <path
                d="M1 16c2 0 2-1.793 4-1.793m0 0C7 14.207 7 16 9 16s2-1.793 4-1.793m-8 0L1 9l3-1m9 6.207c2 0 2 1.793 4 1.793m-4-1.793L16.5 9 9 6.5m0 0v4.261M9 6.5L4 8m0 0l2.5-6.505H9m4.725 6.581L11.5 1.5 9 1.495m0 0V0"
                stroke="#777"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
)
