import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const PinIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 18 18">
        <path
            fillRule="evenodd"
            d="M18 5.498L12.455.001 10.02 2.436l1.034.992-2.396 2.396H5.17L3.73 7.485l3.042 3.042-6.627 6.627c-.194.194-.194.508 0 .701s.508.194.701 0l6.627-6.627 3.186 3.186 1.623-1.623V9.428l2.393-2.393.894.894L18 5.498zm-6.563-3.076l1.021-1.021 4.136 4.1-1.025 1.025-.894-.894-3.384 3.385v3.363l-.632.632-5.575-5.575.538-.621h3.446l3.402-3.403-1.033-.991z"
            fill="#404040"
        />
    </SvgIcon>
)
