import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const DownloadIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 28 28">
        <path
            fillRule="evenodd"
            d="M14 2a.5.5 0 0 1 .5.5v16.793l4.646-4.646a.5.5 0 1 1 .707.707l-5.5 5.5a.5.5 0 0 1-.707 0l-5.5-5.5a.5.5 0 1 1 .707-.707l4.646 4.647V2.5A.5.5 0 0 1 14 2zM5.5 7a.5.5 0 0 0-.5.5v17a.5.5 0 0 0 .5.5h17a.5.5 0 0 0 .5-.5v-17a.5.5 0 0 0-.5-.5H17a.5.5 0 1 1 0-1h5.5A1.5 1.5 0 0 1 24 7.5v17a1.5 1.5 0 0 1-1.5 1.5h-17A1.5 1.5 0 0 1 4 24.5v-17A1.5 1.5 0 0 1 5.5 6H11a.5.5 0 1 1 0 1H5.5z"
            fill="#0D0D0D"
        />
    </SvgIcon>
)
