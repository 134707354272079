import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const CheckIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <path
            fillRule="evenodd"
            d="M17.501 5.58L7.069 15.834l-4.568-4.49.589-.579 3.979 3.911L16.912 5l.589.579z"
            fill={props.color || '#477306'}
        />
    </SvgIcon>
)
