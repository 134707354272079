import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const MailIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 20 14">
        <path
            d="M0 .5A.5.5 0 0 1 .5 0h19a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5V.5zm1 .707V13h18V1.207l-8.646 8.646a.5.5 0 0 1-.707 0L1 1.207zM17.793 1H2.207L10 8.793 17.793 1z"
            fill="#fff"
        />
    </SvgIcon>
)
