import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const ShopMarkerIcon = (props?: SvgIconProps) => (
    <SvgIcon {...props} style={{ width: 40, height: 48 }} viewBox="0 0 40 48">
        <g filter="url(#A)">
            <path
                d="M8 16.5C8 8.39 14.008 4 20 4s12 4.39 12 12.5c0 4.052-1.884 7.883-4.183 11.139s-5.099 6.057-7.092 8.05l-.018.018a1 1 0 0 1-1.414 0l-.018-.018c-1.993-1.993-4.793-4.792-7.092-8.05S8 20.552 8 16.5z"
                fill="#eb0a0a"
            />
        </g>
        <circle cx="20" cy="16" r="10" fill="#fff" />
        <path d="M25.232 18.625h-1.313l-1.722-5.305h1.205l1.181 3.73h.015l1.197-3.73H27l-1.768 5.305z" fill="#000" />
        <path
            d="M20.985 18.1c-.425.417-.896.633-1.498.633-.957 0-1.714-.587-1.714-1.591 0-.741.564-1.251 1.189-1.537-.286-.348-.502-.672-.502-1.127 0-.734.68-1.212 1.382-1.212.803 0 1.46.432 1.46 1.274 0 .618-.409 1.042-.942 1.32l.687.842c.108-.193.162-.417.193-.633h.911c-.054.479-.216.934-.51 1.305l1.081 1.259h-1.297l-.44-.533zm-1.467-1.815c-.371.162-.741.448-.741.88 0 .386.355.695.757.695.378 0 .649-.193.896-.471l-.911-1.104zm.371-2.317a.47.47 0 0 0-.479.471c0 .332.216.548.417.795.301-.193.541-.371.541-.757a.49.49 0 0 0-.479-.51z"
            fill="red"
        />
        <path d="M13 13.32h3.969v.981h-2.803v1.135h2.572v.903h-2.572v1.305h2.865v.981H13V13.32z" fill="#000" />
        <defs>
            <filter id="A" x="0" y="0" width="40" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="A" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="B" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.32 0" />
                <feBlend in2="A" />
                <feBlend in="SourceGraphic" />
            </filter>
        </defs>
    </SvgIcon>
)
