import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const ResidentialIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 18 17">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" fill="#777">
            <path d="M16.313 8.854a.5.5 0 0 0 .707-.707l-.707.707zM8.667.5L9.02.146a.5.5 0 0 0-.707 0L8.667.5zM.313 8.146a.5.5 0 1 0 .707.707l-.707-.707zm2.039 1.539a.5.5 0 0 0-1 0h1zm13.63 0a.5.5 0 1 0-1 0h1zm-2.87-8h.5a.5.5 0 0 0-.5-.5v.5zm-1.778 0v-.5a.5.5 0 0 0-.5.5h.5zM8.313.146l-8 8 .707.707 8-8-.707-.707zM1.352 9.685v6.119h1V9.685h-1zM2.548 17h12.238v-1H2.548v1zm13.434-1.196V9.685h-1v6.119h1zm1.039-7.658l-3.555-3.556-.707.707 3.556 3.556.707-.707zm-3.409-3.202V1.685h-1v3.259h1zm-.5-3.759h-1.778v1h1.778v-1zm.354 3.406l-1.778-1.778-.707.707 1.778 1.778.707-.707zm-1.778-1.778L9.02.146l-.707.707L10.98 3.52l.707-.707zm-.854-1.128v1.481h1V1.685h-1zM14.786 17c.66 0 1.196-.535 1.196-1.196h-1c0 .108-.088.196-.196.196v1zM1.352 15.804c0 .66.535 1.196 1.196 1.196v-1c-.108 0-.196-.088-.196-.196h-1z" />
            <path d="M6.927 8.848v-.5a.5.5 0 0 0-.5.5h.5zm3.478 0h.5a.5.5 0 0 0-.5-.5v.5zM7.427 16.5V8.848h-1V16.5h1zm-.5-7.152h3.478v-1H6.927v1zm2.978-.5V16.5h1V8.848h-1z" />
        </svg>
    </SvgIcon>
)
