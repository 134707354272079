import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const SearchIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 22 22">
        <path
            d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C9.93314 15 11.6824 14.217 12.9497 12.9497C14.217 11.6824 15 9.93314 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 10.029 15.2441 11.8823 13.9994 13.2923L21.8536 21.1464C22.0488 21.3417 22.0488 21.6583 21.8536 21.8536C21.6583 22.0488 21.3417 22.0488 21.1464 21.8536L13.2923 13.9994C11.8823 15.2441 10.029 16 8 16C3.58172 16 0 12.4183 0 8ZM7.5 3.5C7.5 3.22386 7.72386 3 8 3C10.7614 3 13 5.23858 13 8C13 8.27614 12.7761 8.5 12.5 8.5C12.2239 8.5 12 8.27614 12 8C12 5.79086 10.2091 4 8 4C7.72386 4 7.5 3.77614 7.5 3.5Z"
            fill="#777777"
        />
    </SvgIcon>
)
