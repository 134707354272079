import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const LegalEntityIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 18 14">
        <path
            fillRule="evenodd"
            d="M12.6 0H5.4v3.125H0V14h18V3.125h-5.4V0zM14 4H4v9h10V4zm1 9h2V4h-2v9zM1 4h2v9H1V4zm10.7-.875V.875H6.3v2.25h5.4z"
        />
    </SvgIcon>
)
