import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const CommercialIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 17 20">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" fill="none">
            <path
                d="M5.175 18.652c0 .192.156.348.348.348h1.391c.192 0 .348-.156.348-.348v-3.478c0-.192-.156-.348-.348-.348H5.523c-.192 0-.348.156-.348.348v3.478z"
                stroke="#777"
            />
            <path
                d="M4.818 6.101h.379c.159 0 .288-.129.288-.287V4.715c0-.159-.129-.288-.288-.288h-.379c-.159 0-.288.129-.288.288v1.098c0 .159.129.287.288.287zm-2.347 0h.379c.159 0 .288-.129.288-.287V4.715c0-.159-.129-.288-.288-.288h-.379c-.159 0-.288.129-.288.288v1.098c0 .159.129.287.288.287zm5.075 0h-.379c-.159 0-.288-.129-.288-.287V4.715c0-.159.129-.288.288-.288h.379c.159 0 .288.129.288.288v1.098c0 .159-.129.287-.288.287zm1.969 0h.379c.159 0 .288-.129.288-.287V4.715c0-.159-.129-.288-.288-.288h-.379c-.159 0-.288.129-.288.288v1.098c0 .159.129.287.288.287zM4.818 9.337h.379c.159 0 .288-.128.288-.287V7.951c0-.159-.129-.287-.288-.287h-.379c-.159 0-.288.129-.288.287V9.05c0 .159.129.287.288.287zm-2.347 0h.379c.159 0 .288-.128.288-.287V7.951c0-.159-.129-.287-.288-.287h-.379c-.159 0-.288.129-.288.287V9.05c0 .159.129.287.288.287zm5.075 0h-.379c-.159 0-.288-.128-.288-.287V7.951c0-.159.129-.287.288-.287h.379c.159 0 .288.129.288.287V9.05c0 .159-.129.287-.288.287zm1.969 0h.379c.159 0 .288-.128.288-.287V7.951c0-.159-.129-.287-.288-.287h-.379c-.159 0-.288.129-.288.287V9.05c0 .159.129.287.288.287zm-4.697 3.236h.379c.159 0 .288-.129.288-.287v-1.098c0-.159-.129-.287-.288-.287h-.379c-.159 0-.288.129-.288.287v1.098c0 .159.129.287.288.287zm-2.347 0h.379c.159 0 .288-.129.288-.287v-1.098c0-.159-.129-.288-.288-.288h-.379c-.159 0-.288.129-.288.288v1.098c0 .159.129.287.288.287zm5.075 0h-.379c-.159 0-.288-.129-.288-.287v-1.098c0-.159.129-.287.288-.287h.379c.159 0 .288.129.288.287v1.098c0 .159-.129.287-.288.287zm1.969 0h.379c.159 0 .288-.129.288-.287v-1.098c0-.159-.129-.288-.288-.288h-.379c-.159 0-.288.129-.288.288v1.098c0 .159.129.287.288.287z"
                fillRule="evenodd"
                fill="#777"
            />
            <path
                d="M1 18.304c0 .384.311.696.696.696h9.043c.384 0 .696-.311.696-.696V3.696c0-.384-.311-.696-.696-.696H1.696C1.311 3 1 3.311 1 3.696v14.609zM6.218 2.5v-.804c0-.384.311-.696.696-.696h8.391c.384 0 .696.311.696.696v14.609c0 .384-.312.696-.696.696h-3.587"
                stroke="#777"
                strokeLinejoin="round"
            />
        </svg>
    </SvgIcon>
)
