import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const CopyIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M7 1.996L16.213 2 20 5.951v13.053H7V1.996zm1 1v15.007h11V7l-4 .001V3l-7-.004zm8 .207v2.81h2.675L16 3.203z"
        />
        <path d="M4 6h1v15h11v1H4V6z" />
    </SvgIcon>
)
