import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const NotFoundIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="38 -18 485 536">
        <g fill="none" stroke="currentColor">
            <path strokeDasharray="2.999,1.999" d="M278.875 502.626c-6.2 8.1-22.4 23-49 7.1" />
            <path strokeDasharray="3.086,2.086" d="M221.675 504.326c-33.2-21.9-56.9-31.7-84.6-23.2" />
            <path strokeDasharray="2.977,1.977" d="M128.075 484.826c-24.8 14.2-24.2 54.5-86.2 17.1" />
            <path d="M40.475 501.026a22.63 22.63 0 0 1-2-1.3m97-18.1l-2.4.8-2.2.9m97.7 25.5c-.7-.4-1.3-.9-2-1.3l-2-1.4m57.3-8s-.4.8-1.2 2m171.5-254.4l-17.5 11.3-3.8-23.5-20.7 10.1-2.8-19.1-20.5 8.9-8.8-18.1-17 4.9-82.1 159.1 99 41.8 82.1-159.2zm-173.4 133.8l6.9 103.8 92.2-61.9m-91.3 60.1l5.7-3.5-6.5-2.7zm231.1-450.6l3.9-22.5c1.2-6.7-3.9-13.1-11.4-14.1l-40.6-5.8m-59.7 27l3.9-22.5c1.2-6.7 8.2-11.3 15.6-10.3l40.6 5.8m-73.5 190.6l12.4 17.9 16.6-13.7 9.4 17.4 14.4-14.6 12.5 18.5s10.5-14 11.5-14.6l13.9 15.6 30.4-173.5-107.9-15.5-30.4 173.5 17.2-11z" />
        </g>
        <path d="M286.575 481.526l5.7-3.5-6.5-2.7z" />
    </SvgIcon>
)
