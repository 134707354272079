import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const WarningIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <path
            d="M10.417 7.865v3.801h-.833V7.865h.833zm.208 5.777c0 .345-.28.625-.625.625s-.625-.28-.625-.625.28-.625.625-.625.625.28.625.625z"
            fill={props.color || '#BE4B0A'}
        />
        <path
            fillRule="evenodd"
            d="M10.002 2.499l8.329 14.168H1.667l8.334-14.168zM3.124 15.834h13.75L10.001 4.143 3.124 15.834z"
            fill={props.color || '#BE4B0A'}
        />
    </SvgIcon>
)
