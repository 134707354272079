import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const LinkExternalcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M5 5h6.5v1H6v12h12v-5.5h1V19H5V5zm9.5 0H19v4.5h-1V6.707l-5.646 5.646-.707-.707L17.293 6H14.5V5z"
            fill="#fff"
        />
    </SvgIcon>
)
