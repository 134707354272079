import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon'

export const LocationIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M17.793 18.194l1.518.611c.154.062.329.044.468-.048s.221-.246.221-.41V6.94c0-.201-.123-.382-.312-.458l-6.069-2.444c-.061-.025-.126-.038-.191-.038-.077 0-.155.018-.225.053L8.464 6.387 3.723 4.052c-.155-.076-.339-.068-.486.022S3 4.323 3 4.495v11.407c0 .188.107.359.277.443l4.954 2.44c.073.038.152.057.232.057s.16-.019.232-.057l2.34-1.152c-.125-.312-.206-.634-.244-.959L9 17.548V7.246l4-2.025v7.608a3.81 3.81 0 0 1 1-.29V5.23l5 2.042v10.339l-.803-.328a3.72 3.72 0 0 1-.404.91zM8 7.21L4 5.294v10.302l4 1.953V7.21zm7.706 7.683c-.665-.553-1.635-.553-2.3 0-.683.568-.839 1.547-.364 2.295l1.514 2.387 1.514-2.387c.475-.748.319-1.727-.364-2.295zm-2.944-.758a2.81 2.81 0 0 1 3.587 0 2.74 2.74 0 0 1 .568 3.579l-1.938 3.054a.5.5 0 0 1-.423.232.5.5 0 0 1-.424-.232l-1.938-3.054a2.74 2.74 0 0 1 .568-3.579zM14.55 17.1a.9.9 0 1 0 0-1.8.9.9 0 1 0 0 1.8z"
            fill="#404040"
        />
    </SvgIcon>
)
