import { SvgIcon } from '@mui/material'
import type { SvgIconProps } from '@mui/material/SvgIcon'

export const ErrorIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 160 160">
        <g clipPath="url(#A)">
            <g stroke="#c80000" strokeWidth="1.66" strokeMiterlimit="10">
                <path d="M79.915 160v-18.037m-7.168 17.717l1.615-17.969m-8.729 17.001l3.223-17.75m-10.221 16.156l4.797-17.39m-11.625 15.168l6.337-16.885m-12.933 14.064l7.822-16.251m-14.145 12.851l9.254-15.488m-15.244 11.536l10.603-14.596m-16.218 10.119l11.87-13.587m-17.055 8.627l13.035-12.47m-17.764 7.066l14.105-11.25m-18.323 5.438l15.059-9.935M9.471 117.91l15.884-8.552m-18.998 2.085l16.585-7.093m-19.106.368l17.151-5.574M1.922 97.802l17.587-4.013M.641 90.736l17.88-2.419M0 83.588l18.023-.811M0 76.412l18.023.811M.641 69.265l17.88 2.419M1.922 62.198l17.587 4.013M3.836 55.275l17.151 5.581M6.357 48.557l16.585 7.093M9.471 42.09l15.884 8.552M13.151 35.93l15.059 9.935M17.369 30.118l14.105 11.25m-9.376-16.654l13.035 12.47m-7.85-17.43l11.87 13.587m-6.255-18.064l10.603 14.596m-4.613-18.548l9.254 15.488M45.204 7.925l7.829 16.251M51.807 5.104l6.337 16.885m.491-19.107l4.797 17.389m2.201-18.983l3.223 17.751M72.747.32l1.615 17.969M79.915 0v18.037M87.09.32l-1.615 17.969m8.729-17.001l-3.223 17.751m10.221-16.157l-4.797 17.389m11.624-15.167l-6.337 16.885m12.933-14.064l-7.822 16.251m14.146-12.851l-9.254 15.488m15.243-11.536l-10.602 14.596m16.217-10.119l-11.87 13.587m17.056-8.627l-13.036 12.47m17.765-7.066l-14.105 11.25m18.322-5.438l-15.059 9.935m18.739-3.775l-15.883 8.552m18.997-2.085l-16.585 7.093M156 55.275l-17.151 5.581m19.066 1.342l-17.587 4.013m18.868 3.054l-17.88 2.419m18.52 4.728l-18.023.804m18.023 6.372l-18.023-.811m17.383 7.959l-17.88-2.419m16.599 9.485l-17.587-4.013M156 104.725l-17.151-5.581m14.63 12.299l-16.585-7.093m13.471 13.56l-15.883-8.552m12.203 14.712l-15.059-9.935m10.842 15.747l-14.105-11.25m9.376 16.654l-13.036-12.47m7.85 17.43l-11.87-13.587m6.255 18.064l-10.602-14.596m4.613 18.548l-9.254-15.488m2.937 18.888l-7.829-16.251m1.226 19.072l-6.337-16.885m-.49 19.107l-4.797-17.39m-2.201 18.984l-3.223-17.75M87.09 159.68l-1.615-17.969" />
            </g>
            <rect x="60" y="60" width="40" height="40" rx="20" fill="#c80000" />
            <path
                d="M80 81.176l10.494 10.489 1.178-1.178-10.494-10.489 10.494-10.489-1.178-1.178L80 78.82 69.506 68.331l-1.178 1.178 10.494 10.489-10.494 10.489 1.178 1.178L80 81.176z"
                fill="#fff"
            />
        </g>
        <defs>
            <clipPath id="A">
                <path fill="#fff" d="M0 0h160v160H0z" />
            </clipPath>
        </defs>
    </SvgIcon>
)
